//
// breadcrumb.scss
//


ol.breadcrumb {
    background-color: transparent;
    padding:0;
	margin-bottom:calc(var(--bs-breadcrumb-margin-bottom) / 2);
}

/* breadcrumbs font size */
.breadcrumbs li.breadcrumb-item { font-size: 140%; font-weight: 800; }

.breadcrumbs h5.bg-danger { position:fixed; z-index: 1; margin-top: -10px; }

body[data-sidebar='dark'] {
    .breadcrumbs {
        i.bx { color: $troly-grey-5; }
        .breadcrumb-item {
            color: $troly-grey-2;
            > a { color: $troly-grey-6; }
				&:before { color: $troly-grey-5; }
        }
    }
}

/* default animation for icon click */
.breadcrumbs > i { transition: transform .2s ease-out; cursor:pointer; }

.breadcrumbs > i.float-end {
	font-size:22px;
	color:var(--bs-secondary);
	margin-right: 2px;
	&.bxs-window-alt { margin-right: 1px; }
	&:hover { color: var(--bs-dark); }
}

.nav-summary-partial .breadcrumbs > i.bxs-caret-down-square { transform: rotate(90deg); }
.nav-summary-no .breadcrumbs > i.bxs-caret-down-square { transform: rotate(180deg); }

.nav-summary-partial .row.page-summary, 
.nav-summary-no .row.page-summary,
.nav-summary-no .row.page-navigation {  max-height: 0px; }

.row.page-summary { max-height: 60vh; overflow: hidden; transition: max-height .3s ease-in-out; }
.row.page-navigation { max-height: 10vh; overflow: hidden; transition: max-height .3s ease-in-out; }

div.page-content {
	padding-top: calc($header-height + 59px); /* add breadcrumbs height*/
	.breadcrumbs {
		width: 100%;
		padding-top: 15px;
		padding-right:14px;
		backdrop-filter: blur(5px); position:fixed;top: 78px;z-index: 99;
		margin-left:-20px;padding-left:20px; /* hides the shadow from elements underneath (visible on scroll) */
	}
}

body.sidebar-enable nav.breadcrumbs { width:calc(100% - $sidebar-width + 5px); }
body.sidebar-enable.vertical-collapsed nav.breadcrumbs { width:calc(100% - $sidebar-collapsed-width + 5px); }