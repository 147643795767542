
@import "./colours";

.tox { 
	&.tox-tinymce {
		border:none;
		border-radius: 0;
		border-bottom:1px solid var(--bs-secondary);
		&:hover, &:focus {
			border-bottom:2px solid var(--bs-dark);
		}
	}

	.tox-editor-header {
		box-shadow: none !important; /* need css-rule weight to override the default tinycme */ 

		.tox-tbtn__select-label {
			font-size: smaller;
		    text-transform: uppercase;
			font-family: var(--bs-font-sans-serif)
		}
	}

	.tox-edit-area__iframe {
		border-radius:4px 4px 0 0;
		background-color: rgba($troly-grey-0,0.5) !important;
	}

}

div.card:hover {
	.tox .tox-edit-area__iframe {
		background-color: rgba($troly-primary,0.03) !important;
	}
}

