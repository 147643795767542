@import "../../colours";

:root {
	--mat-select-trigger-text-size:13px;
}

.row > .toolbar {
	
	display:flex;
	padding-bottom: $card-spacer-y;
	
	.toolbar-left { margin-right: auto; display:flex; gap:4px; }
	.toolbar-right { 
		margin-left: auto; display:flex; gap:4px; 
		.btn-group .dropdown-toggle { padding-left: calc($grid-gutter-width / 3); }
	}
	.toolbar-left, .toolbar-right { &.mostly-icon-buttons { gap:2px; } }

	/* buttons organised in a toolbar need a little between them */
	.btn-primary, .btn-outline-primary, .btn-group, .dropdown .btn-primary  {
	  text-align: left;
	  &:not(.btn-icon):not(.btn-card-option) { min-width: 180px; }
	}
	.btn {
		/* creates a button without a label -- only an icon,and aligns with other buttons witha a label */
		i {
			line-height: 14px;
			vertical-align: middle;
			margin:0 1px;
			font-size:15px;
		}
	}
  }

.btn { transition: opacity 0.2s ease; }
.btn[disabled] { cursor:not-allowed; }
	
.toolbar .card-footer, .modal-footer {
	.dropdown-toggle-split { 
		width: 35px; 
		& > i { font-size: 18px; } 
	}
}

.card-header {
	/* buttons in a card header need a little between them */
	.btn, .btn-group, .dropdown  {
		float: right;
		margin-left: calc($grid-gutter-width / 6);
	}
}
.row > .toolbar, .card-header {
/* buttons in a toolbar, or header have standard padding and look-and-feel */
	.btn-primary, .btn-outline-primary, .btn-secondary, .btn-outline-secondary, .btn-light, .btn-outline-light,  .btn-danger, .btn-outline-danger, .btn-outline-dark {
		@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm);  
		text-transform: uppercase;
		font-family: var(--bs-font-sans-serif);
		> i[class*='chevron'] { margin-left:-0.25em; float:right; padding-left:0.07rem; }
	}

	.btn-group > button { padding-right:0.4rem; }
	.btn .dropdown.show .dropdown-toggle .bx-chevron-down {
		transform: rotate(180deg);
	}
}


button:not(.btn).dropdown-toggle {
	//* used as quick contextual menus *//
	border: none;
	background: none;
	opacity:0.3;
}

body[data-sidebar='dark'] {
	button:not(.btn).dropdown-toggle { color:$troly-grey-0; }
	.mat-mdc-chip.mat-mdc-standard-chip { color:$troly-grey-1;}
}



:root {
	.mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.mat-mdc-button-base {
		/* the default icon is crazy big - 48px - not sure how to change this in the angular theme -- see assets/scss-troly/_theme*/
		--mdc-icon-button-state-layer-size: 36px; /* date-picker padding and 'ripple' */
		padding:11px 10px 9px 10px; margin-right:2px;
	}
}

.mat-mdc-icon-button {
	--mdc-icon-button-icon-size:17px !important; /* makes the date-picker icon into a more realistic size.*/
}

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.tags .mat-chip-listbox-wrapper { margin-bottom: 1rem; margin-top: 0.5rem; }
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
.card:hover .mat-chip-listbox.tags {
	.mat-mdc-chip.mat-mdc-standard-chip {
		cursor: pointer;
		&.btn-light { background-color: rgba($troly-primary, 0.03); }
		&.btn-dark { background-color: rgba($troly-primary, 0.08); }
	}
}

.dropdown.disabled, .btn[disabled] {
	cursor: not-allowed;
}


form.success button.loaded {
	color: transparent !important; /** bxs-check-circle */
	&:after { content: "\ed31" }
}

button.loading, button.loading-error, button.loaded {
	position: relative;
	&:after {
		font-family: 'boxicons';
		font-size: 17px;
		top: calc(50% - 10px);
		left: calc(50% - 8px);
		position: absolute;
		color:white;
	}
}

button.loading{
	color: transparent !important;
	&:after { 
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
		content: "\eb46"; /* bxs-loader-alt */
		font-size: 15px;
	}
}
.btn-group {
	button.loading:after { left: calc(50% + 11px); }
	button.dropdown-toggle.loading:after { content:''; }
}
button.loading-error {
	color: transparent !important;
	background-color: $danger !important;
	border-color: $danger !important;
	&:after { 
		content: "\eda4" !important; /* bxs-error-circle */
	}
}



.btn-secondary { color: white !important; }
.btn.btn-lg { 
	padding-bottom: calc(var(--bs-btn-padding-y) - 1px); /* the default Poppins font stands a little high on the line*/
	.bx { vertical-align: middle; }
	.bx[matPrefix] { margin-right:1px;margin-left:-4px; }
	.bx[matSuffix] { margin-right:-4px;margin-left:1px; }
} 