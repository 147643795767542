//
// _menu.scss
//

.vertical-menu {
    transition: width .3s ease-in-out;
    box-shadow: none;//$box-shadow-md;
    z-index:500;
	 background:none;
}

.main-content {
   transition: margin-left .3s ease-in-out;
   overflow: visible;
}

#sidebar-menu {

    .is-parent.mm-active:after { transform: rotate(-180deg); }
    .has-arrow:after {
          content: "\F0143";
    }

    .mm-collapse {

        transition: max-height .3s ease-in-out;
        max-height:0px;
        overflow: hidden;
        display: block;

        &.mm-show {
            max-height:45vh;
            display: block;
        }
    }
    


    ul {
        li {
			  white-space: nowrap;
			  overflow: hidden;
			  padding-bottom:0.1rem;

            & > a { border-left:4px solid transparent; & > i { padding-bottom:0; }}
            & > a.active { 
					border-left-color:var(--bs-primary); color:var(--bs-primary) !important; 
					background-color: $troly-white;
					border-radius: 0 60px 60px 0;
					margin-right:5px;
               & > i { color: var(--bs-primary) !important; }
            }
            
				span.badge {
					vertical-align: top;
					padding:0.2rem 0.4rem;
					margin-top: 2px;
					> a { display:unset;padding:0;font-size:11px;color:$troly-white; }
				}

            a.side-nav-link-ref {

               em:before { 
                  content:" ";
                  height: 3px;
                  display: block;
                  background: $troly-grey-1;
                  margin-top: 8px;
                  margin-bottom: 9px;
                  width: 78%;
               }
                &:hover {
                    color: var(--bs-primary);
					
					transition: border-left-width 0.2s;
					border-left-width: 8px;
				    border-left-color: var(--bs-primary);

                    i {
                        color: var(--bs-primary);
                    }
                }
            }

            ul.sub-menu {
                padding: 0;

                span.badge {
                     vertical-align: top;
                     padding:0.2rem 0.4rem;
                     margin-top: 2px;
                     > a { display:unset;padding:0;font-size:11px;color:$troly-white; }
               }

                li {
                    
                    a.side-nav-link-ref {
                        padding: 0.4rem 1.5rem 0.4rem 1.5rem;
                    }

                    ul.sub-menu {
                        
                        li {
                            a.side-nav-link-ref {
                                padding: 0.4rem 1.5rem 0.4rem 2.5rem;
                                font-size: 13.5px;
                            }
                        }
                    }
                }
            }
        }
    }
}
body[data-sidebar='dark'] {
	#sidebar-nav { background-color:transparent; }
   #sidebar-menu ul li a em:before { background-color:$troly-grey-8; }
   #sidebar-menu ul li a.active, 
   #sidebar-menu ul li a:hover, /** Skote doesn't correctly implement the "active" colour -- well it does, but we have somehow lost the mm-active on the li.. **/
   #sidebar-menu ul li ul.sub-menu li a.active,
   #sidebar-menu ul li ul.sub-menu li a:hover { color:$sidebar-dark-menu-item-active-color; i { color: $sidebar-dark-menu-item-active-color; } }
	#sidebar-menu ul li a.active { background-color:$troly-dark !important; }
	ul.sub-menu li span.badge a { color:black !important; }
}

.menu-title {
    line-height: 20px;
}

.mm-active {
    color: var(--bs-primary) !important;

    & > a { border-left: 4px solid transparent; }

    .active {
        color: var(--bs-primary) !important;
        border-left: 4px solid $primary;

        i {
            color: $primary !important;
        }
    }

    > i {
        color: $primary !important;
    }
}

@media (max-width: 992px) {
    .vertical-menu {
        display: none;
    }

    .main-content {
        margin-left: 0 !important;
    }

    body.sidebar-enable {
        .vertical-menu {
            display: block;
        }
    }
}

.vertical-collapsed .vertical-menu, .no-decorations {
    /* hide padges and buttons from the nav -- used on expansion so that the content doesn't wrap */
    span.badge,
    a.has-arrow:after { display: none !important; }
}

// Enlarge menu
.vertical-collapsed {
 
    .main-content {
        margin-left: $sidebar-collapsed-width;
    }

    // Side menu
    .vertical-menu {
        // scroll
        width: $sidebar-collapsed-width;
    }

    #sidebar-menu li a span { display:none; }
}
