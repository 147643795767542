
$troly-blue: #0288d1; // on-brand
$troly-indigo: #4c60c9; // brand extension see https://coolors.co/e91e63-0288d1-4c60c9-9537c0-c55a60-f57c00-faa000-ffc400-43a047
$troly-purple: #9537c0; // on-brand, was #6f42c1
$troly-pink: #e91e63; // on-brand
$troly-orange: #f57c00; // on-brand, was #f1734f           
$troly-yellow: #ffc400; // on-brand
$troly-amber: #FAA000; // brand extension see https://coolors.co/e91e63-0288d1-4c60c9-9537c0-c55a60-f57c00-faa000-ffc400-43a047
$troly-green: #43A047; // on brand, should not be used next to success/danger
$troly-dark: #2f363a;
$troly-cyan: #50a5f1;

$troly-primary: $troly-blue;
$troly-success: #34c38f;
$troly-danger: #dd4d4d;
$troly-warning: $troly-yellow;
$troly-info: $troly-primary;

$troly-grey-9: $troly-dark; // onbrand, then https://mdigi.tools/color-shades/#2f363a
$troly-grey-8: #262c2f;
$troly-grey-7: #3f494e;
$troly-grey-6: #59666e;
$troly-grey-5: #72838d;
$troly-grey-4: #919fa6;
$troly-grey-3: #b1bac0;
$troly-grey-2: #d0d6d9;
$troly-grey-1: #eff1f2;
$troly-grey-0: #f2f3f4;

$troly-bg: #eef4f8;
$troly-offwhite: #f7f7fa; // cards footer
$troly-white: #ffffff;
$troly-black: #000000;

$primary-palette: (
	50: $troly-primary,
	100: $troly-primary,
	200: $troly-primary,
	300: $troly-primary,
	400: $troly-primary,
	500: $troly-primary,
	600: $troly-primary,
	700: $troly-primary,
	800: $troly-primary,
	900: $troly-primary,
	A100: $troly-primary,
	A200: $troly-primary,
	A400: $troly-primary,
	A700: $troly-primary,

	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(white, 0.87),
		400: rgba(white, 0.87),
		500: rgba(white, 0.87),
		600: rgba(white, 0.87),
		700: rgba(white, 0.87),
		800: rgba(white, 0.87),
		900: rgba(white, 0.87),
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: rgba(white, 0.87),
		A700: rgba(white, 0.87)
	)
);

$danger-palette: (
	50: $troly-danger,
	100: $troly-danger,
	200: $troly-danger,
	300: $troly-danger,
	400: $troly-danger,
	500: $troly-danger,
	600: $troly-danger,
	700: $troly-danger,
	800: $troly-danger,
	900: $troly-danger,
	A100: $troly-danger,
	A200: $troly-danger,
	A400: $troly-danger,
	A700: $troly-danger,


	contrast: (
		50: rgba(black, 0.87),
		100: rgba(black, 0.87),
		200: rgba(black, 0.87),
		300: rgba(white, 0.87),
		400: rgba(white, 0.87),
		500: rgba(white, 0.87),
		600: rgba(white, 0.87),
		700: rgba(white, 0.87),
		800: rgba(white, 0.87),
		900: rgba(white, 0.87),
		A100: rgba(black, 0.87),
		A200: rgba(black, 0.87),
		A400: rgba(white, 0.87),
		A700: rgba(white, 0.87)
	)
);