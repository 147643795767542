
.table-pagination { /* pagination to listcomponent */
   font-size: 80%;
	padding-top:1.5rem;
	padding-bottom:0;
	
   select { 
      font-size: 0.65rem;
      max-width: 150px;
      border:none;
      color:var(--bs-card-color);
      background-color:transparent;
   }

   .pagination { 
      margin-bottom:0; 
      li.page-item {
         padding-left: 2px;
         padding-right: 2px;
         margin-bottom: 0.9rem;
      }
   }

   li.page-item:not(.disabled) > a { color:$troly-primary; }
   li.page-item.active > a { font-weight: 800; color: var(--bs-yellow);  border-color: rgba(var(--bs-yellow-rgb), 0.75); background: rgba(var(--bs-yellow-rgb), 0.05); box-shadow: var(--bs-box-shadow); }
   a.page-link { border-color:rgba($troly-grey-9,0.15); font-size: 0.8rem; background-color:transparent; }
   
}


body[data-sidebar='dark'] {
   .row.list-component-pagination { 
      color: $troly-grey-2; 

      a.page-link { border-color:$troly-grey-7; }
   }
}