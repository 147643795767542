.troly-snackbar-x { 
	--mdc-snackbar-supporting-text-size: 13px;
	margin-top: 4rem !important; color: rgba(255, 255, 255, 0.9); 
	min-width: 400px;
	.mat-mdc-simple-snack-bar { 
		font-family: var(--bs-font-sans-serif);
		font-size: small;
	}
}

.troly-snackbar-x .mdc-snackbar__surface:before { 
	padding-top: 8px; padding-left: 14px; border-radius: 0;
	border-top-left-radius:3px; border-bottom-left-radius:3px; 
	font-family: boxicons; display:block; width: 50px; height: 50px; font-size: 20px; 
}

/*.troly-snackbar-x .mat-mdc-snack-bar-label { margin-left: 26px; height:50px; }*/

.troly-snackbar-x.troly-snackbar-success .mdc-snackbar__surface:before { content: "\ea46"; background-color: $troly-success; }
.troly-snackbar-x.troly-snackbar-warning .mdc-snackbar__surface:before { content: "\eac7"; background-color: $troly-warning; }
.troly-snackbar-x.troly-snackbar-error .mdc-snackbar__surface:before { content: "\ec8e"; background-color: $troly-danger; }