//
// Google font - Poppins
//

//@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700&display=swap');

@font-face {
	font-family: "Poppins";
	src: url(../../../fonts/Poppins-Regular.ttf) format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "Poppins";
	src: url(../../../fonts/Poppins-SemiBold.ttf) format("truetype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "Montserrat";
	src: url(../../../fonts/Montserrat-VariableFont_wght.ttf) format("truetype-variations");
	font-weight: 125 950;
	font-style: normal;
}