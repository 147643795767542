

@import "../../colours";
//
// _card.scss
//


/*** Sizes of cards ***/
.card.thin { min-height:0 !important; }
.modal-content .card { margin-bottom:0; }


.card.transparent {
	box-shadow:none !important;
	border:none;
	background:transparent !important;
}

/*** Shadows ***/
.card {
	overflow: hidden; /* used n places where we scroll content in and out (eg pos) */
   /* any cards inside an other is a data widget and needs tono look like a standalone card */
   .card { 
	   border:1px solid var(--bs-card-cap-bg);
        box-shadow:none; margin-bottom:0; 
        .card-header {  
            padding: calc(var(--bs-card-cap-padding-y)/2) calc(var(--bs-card-cap-padding-x)/2);
			padding-bottom: calc(var(--bs-card-cap-padding-y));
            h4.card-title { font-size: 0.85rem; i { margin-left:0; font-size:x-large; } } /* card header when card is a sub card */
        }
        .card-body { padding: calc(var(--bs-card-cap-padding-y)/2) calc(var(--bs-card-cap-padding-x)/2); }
    } 
}

/*** Card Structure ***/
.card .card-header, .card .modal-header {
   background-color: transparent; /** remove the standard bootstrap header bg color */
   border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0 !important;

   h4.card-title, h4.modal-title {
      font-size:1.1rem; line-height: 1.1;
      display:inline-block; /* card titles are not blocks, and float left so that anything after them can float right*/
      margin-top: calc(var(--bs-card-title-spacer-y) / 2); /* tweak the appearance of vertical positioning to better align with buttons */
		margin-bottom: 0; /* card-header already has padding, and the optional subsequebt p tag does too */
      & > i {
        font-size: large; vertical-align: middle;
        margin-left: calc(var(--bs-card-cap-padding-x) / 3);
        margin-right: calc(var(--bs-card-cap-padding-x) / 3);
      }
      & > span .badge { margin: 0 0.5rem; }
   }
	h4.modal-title { font-size: 1rem; }
	p.card-subtitle { 
	    clear:left;
	    margin-top: 0.6em; /* allows enough space to clear any card buttons in the card-header.*/
    }
   button { float:right; }
   
   &:after { display:block;clear:both;content:""; } /* reset all floats from above */
   & + .card-body { padding-top:0; } /* reduce top padding for the first body in a card, when right after a card-header */

}

#layout-wrapper.beginner p.card-subtitle { display:none }

.card .card-header, .card .modal-header {		 
	padding-bottom: calc(var(--bs-card-cap-padding-y) / 2);
}
.card .card-header + .card-body, .card .modal-header + .modal-body { padding-top: calc(var(--bs-card-cap-padding-y) / 2); }

.is-embeded {
	.card-header, .modal-header, .card-footer, .modal-footer { display: none !important; }
	.card-body, .modal-body { padding: 0 !important; }
}


.card {
	.chartViewMultiple {
		text-align: center;
		font-size:10px;
		span { font-size:14px; }
		&, a { color: var(--bs-secondary); }
		a.selected { font-weight:bold; color: var(--bs-primary); }
	}
}
.card .card-footer, .card .modal-footer {
    padding:calc(var(--bs-card-cap-padding-y) / 1.75) calc(var(--bs-card-cap-padding-x) / 1.25);
    font-size:0.7rem;
	 display: flex;
	 flex-wrap: nowrap;
	 justify-content: space-between; 
	 flex-direction: row-reverse; /* for convenience, the first item is always at the right */
    p { margin-bottom: 0.25rem; }
    p:last-of-type { margin-bottom:0; }
	& > button[type=submit] { margin-left:auto;}

	& > div:not(.btn-group) { display:flex; gap:0.4rem; /*when we have a div we take is as a container of buttons or else */ }
}
.card .card-footer:last-of-type, .card .modal-footer:last-of-type {
	/* rounded corders are only appled to the last footer displayed, to match the parent card --- additional footers remain unchanged */
	border-radius: 0 0 calc(var(--bs-card-border-radius) - 0px) calc(var(--bs-card-border-radius) - 0px) !important;
}

.card.auto-height-flash-card,
.card.full-height-flash-card { /* the flash card is the left-most summary card which always takes the full summary height */
    .card-header { 
        overflow:hidden;height:180px;padding:0; /** always showing image or map and needs to fill the card */
        
        h4 { /* overrides the "standard" inline block of card headers */
            border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0 !important; 
            margin-top:0;display:block;position:absolute;width:100%;
            margin-bottom:0; 
        } 

        .header-background {
            border-radius: var(--bs-card-border-radius) var(--bs-card-border-radius) 0 0 !important;
            height: 180px; background-size: 100%; width:100%;
				background-repeat: no-repeat;
				background-position: center;
       }
    } 

    .card-body.contains-avatar {
		 z-index: 50;
        /* offsetting up the body, so that it overlaps the card-header */
        .avatar-lg { margin-top: -4rem; }
        .avatar-md { margin-top: -3rem; }
    }

	.card-body {
		border-top: 5px solid #2f363a;
	}
}

.card.full-height-flash-card,
.card.full-height-card { height: 420px; }
.card.half-height-card { height: 201px; }

.card.selected-card { /* using the selectable directive -- highlights a certain card in the page based on url fragment */
   animation: highlighter 18s ease-out infinite;
   border:1px solid $warning !important;
   &:hover, &:active {
		animation: none;
	}

	.mat-mdc-form-field.bx-pulsating {
		/*animation: pulsate 1.5s infinite alternate;*/
		.mat-mdc-text-field-wrapper { animation: highlighter 18s ease-out infinite; border:2px solid $warning !important; }
	}
	&:hover, &:active {
		.mat-mdc-form-field.bx-pulsating .mat-mdc-text-field-wrapper { animation: none; }
	}
}


.col-2.disabled, .col-3.disabled, .col-4.disabled, .col-5.disabled, .col-6.disabled, .col-7.disabled, .col-8.disabled, .col-9.disabled, .col-10.disabled, .col-11.disabled, .col-12.disabled {  opacity:0.5; & img { opacity:0.5; }}








































.card {
	overflow:unset; /* defaults to hidden, and will crop dropdowns and other tooltips */
    p { transition:opacity 0.2s; opacity:0.9; /* just a dirty trick to show as faded */
	 &.text-muted { opacity:0.5 !important; }
	 &.card-subtitle { opacity:0.65; margin-bottom: 0.5rem; }
	}
	 .alert {
		 opacity:0.8; 
		 &[type=subtle] { opacity:0; }
		 h6 > i { font-size:13px; }
		 a { color:$troly-primary; font-weight: 500; }
		 a:not([href=""]):not([href^="/"]):not([href*="troly"]):after { font-size:80%; content:"\eb3e"; font-family: 'boxicons'; margin-left:0.2rem; }
		}

    /* H3: Key dara point to bring attention to: */
    h3 { font-weight:600; font-size: 17px; sub {font-size:70%; top:-0.02em;font-weight:normal; padding-left:0.3em;}}
    
    /* H4: Card title */
    h4 { font-size: 15px; 
		&.card-title, &.modal-title { 
			color: var(--bs-dark);
			em {
				font-style: normal;
				color: var(--bs-light);
			}
		}
	}

    /* H5: Form section divider: */
    h5 { margin-top: 1em; padding-bottom: 0.5em; font-size: 0.9rem; font-weight: 700; text-align: center; &.disabled { opacity:0.6; }}

	 /* H6: Form question or attribute (for radios and checkboxes */
    h6 { font-weight: 600; &.disabled { opacity:0.6; } }

    &:not(.no-hover):hover {
        p, p.card-subtitle { opacity:1; }
		  .alert { filter: none; }
        h3, h4, h5, h6, mat-panel-title { color:var(--bs-dark); } /* standard headers colour and (below) hover */
    }
}

body[data-sidebar='dark'] {
	.modal-content .card {
		--bs-card-bg: var(--bs-dark);
	}

	.card {
		--bs-card-bg: var(--bs-gray-900);
		border-color: transparent;

		p,
		ul,
		ol {
			color: $troly-grey-1;
		}

		p.text-muted {
			color: $troly-grey-3  !important;
		}

		h3,
		h4,
		h5,
		h6 {
			color: $troly-grey-1;
		}

		h4.card-title, h4.modal-title {
			em { color: $troly-grey-6 }
		}

		&:hover {

			h3,
			h4,
			h5,
			h6,
			mat-panel-title {
				color: $troly-grey-0;
			}
		}

		&.loading:before {
			background: $troly-grey-5;
		}
		&.loading .card-body:before { border-color: $troly-grey-1; }
		&.loading .card-body:after { color: $troly-grey-1; }

		.card {
			border-color: $troly-grey-8;
		}

		.card-footer {
			background-color: $troly-grey-7;
		}
	}
}




.card-help-pellet {
    position: absolute;
    right: 0;
    top: 0;
    margin: 6px;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
    cursor:help;
}

.card-help-pellet .pellet-icon {
    transform: scale(1);
	animation: highlighter 20s infinite;
}

.card:hover { 
	.alert[type=subtle] { opacity:0.8; }
	.card-help-pellet .pellet-wrapper { display: block; }
}


.page-navigation > div > .card {
    box-shadow:none; 
    background-color: transparent;
}

.tab-content .card { 
    
    min-height:140px;
    
    &:not(.soon):hover {
        /*box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%);*/
    
        .card-body > p.text-secondary, .card-body > ul.text-secondary, .card-body > ol.text-secondary {
            color:rgba(0, 0, 0, 0.64) !important;
        }
    }
}

/** 
* All Loading Cards
*/
.card.soon,                 /* feature is coming soon */
.card.loading,/* card is loading data */
.card.loading-slow,         /* connection seems slow */
.card.loading-error {       /* connection or processing error */

    &:not(.is-modal) {

        &:before {
            background: $troly-grey-1;
				width: 100%;
            height: 100%;
            position: absolute;
            z-index: 99; /* above the card content, but below the top bar */
            content: "";
            opacity: 0.9;
				border-radius: var(--bs-card-border-radius);
        }
        div.row { /* force opacity on all card content */
            opacity:0.3;
        }

        .card-body:before { 
            z-index: 100;
            position: absolute;
            text-align: center;
            font-family: 'boxicons';
        }

        .card-body:after { 
            z-index: 100;
            position: absolute;
            text-align: center;
            font-weight: 400;
            line-height: 1.1em;
        }

        &.soon:before { /* a little different where we want to show the heading and only hide the content */
            background: none;
        }
        &.soon .card-body > div, /* allows for h4.card-title ad p.text-secondary */
        &.soon .card-body > button {
            opacity: 0.1;
        }
		  
    }
}

.card-title .badge { margin-left:0.5em;vertical-align: top; }

/** 
* Loading Spinners
*/

.card.loading:not(.is-modal), .card.loading-slow {
    .card-body:before {
        content: "";
        width: 35px;
        height: 35px;
        animation: spinner-border 0.8s linear infinite;
        border: 3px solid $primary;
        border-radius: 50%;
        border-right-color: transparent !important;
        left: calc(50% - 20px);
        top: calc(35% - 20px);
    }
    .card-body:after { 
        content: "loading";
        color: $primary;
        left: calc(50% - 52px);
        text-align: center;
        width: 100px;
        top: calc(35% + 25px);
    }
}
.card.loading-slow { /** slow tweaks: colour, text, animation speed */
    .card-body:before { border-color: $warning; animation-duration: 1.9s !important; }
    .card-body:after { color: $warning; content:"slow connection"; }
}

/** 
* Fixed Loading cards
*/
.card.soon, .card.loading-error {
    .card-body:before {
        content: "\ea97";
        color:$danger;
        font-size: 40px; /* about the same size as the loading spinner */
        left: calc(50% - 15px); /* half the width of the element, set above, in :before */
        top: calc(35% - 35px); /* the height of the logo set in :before */
    }
    .card-body:after { 
        content: "access error: please logout and try again.";
        color: $danger;
        width: 128px;
        left: calc(50% - 64px); /* half the width of the element, set above */
        top: calc(35% + 20px); /* the height of the logo set in :before */
    }
}
.card.soon {
    .card-body:before { opacity: 0.5; content: "\eca9"; color:$secondary; top: calc(55% - 25px); font-size:30px;  }
    .card-body:after { opacity: 0.8;color: $secondary; content:"coming soon"; font-weight:600; opacity:0.65; }
}

/**
* Tweaks for buttons which are loading ( or 'working' ).
*/
.row > .loading[class^='col-'] {
	&:before {
		background: $troly-white;
		width: calc(100% - 24px); /** to erase the negative padding of columns in bootstrap */
		height: 100%;
		position: absolute;
		z-index: 99; /* above the card content, but below the top bar */
		content: "";
		opacity: 0.9;
	}

	&:after { 
		content:"";
		z-index: 100;
		left:calc(50% - 18px);
		top:calc(50% - 17px);
		width: 35px;
		height: 35px;
		animation: spinner-border 0.8s linear infinite;
		border: 3px solid $primary;
		border-radius: 50%;
		border-right-color: transparent !important;
		position: absolute;
  }
}


/**
 * Tweaks for all Accordeons within a card
 */
.card mat-accordion {
    .mat-expansion-panel {
		 --mat-expansion-header-text-color: var(--bs-dark);

        margin-left: -20px;
        margin-right: -20px;
        box-shadow: none; /* removes the shadow from the default accordeon when used as card content */
		  
    
        &:first-of-type {
            border-top-left-radius: 0px !important;
            border-top-right-radius: 0px !important;
        }

        .mat-expansion-panel-header-title {
            font-family: 'Montserrat', 'Helvetica Neue', Roboto, sans-serif !important;
				font-weight: 700;
				font-size: 14px;
				flex-grow: 2;
        }

        .mat-expansion-panel-header-description {
            align-items: center;
        }

        .mat-panel-description .badge { text-transform: uppercase; }
    }
}

body[data-sidebar='dark'] {
	.card mat-accordion  {
		.mat-expansion-panel {
			background-color: $troly-grey-7;

			.mat-expansion-indicator:after,
			.mat-expansion-panel-header-title,
        	.mat-expansion-panel-header-description,
        	.mat-expansion-panel-body {
				color:$troly-grey-2;
		  	}
			/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
			.mat-slider-track-background { background-color:$troly-grey-2; }
			/*TODO(mdc-migration): The following rule targets internal classes of slider that may no longer apply for the MDC version.*/
			.mat-slider-thumb { border-color:$troly-grey-2; }
		}
	}

	.mat-select-panel .card-footer,
	.mat-mdc-autocomplete-panel:not(.search) .card-footer,
	.card-footer.fadeout { box-shadow:0px -10px 10px -10px rgba(47, 54, 58, 1); border-top:1px solid $troly-dark; }
}

/*TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
mat-checkbox .mat-checkbox-label, mat-option .mat-option-text { /** contextual popups are not renddered within the card itself */
   font-family: 'Montserrat', 'Helvetica Neue', Roboto, sans-serif !important;
   width:100%;
}

.mat-mdc-autocomplete-panel .mat-mdc-option { text-overflow:unset; }

.mat-mdc-autocomplete-panel .mat-mdc-option .avatar-xs i { height:42px; width:42px; margin-right:0; font-size:20px; /* setting the default size and layout for the dropdown icon*/ }
.mat-mdc-autocomplete-panel .mat-mdc-option p { line-height: 20px; }

.mat-select-panel .mat-mdc-option:first-child,
.mat-mdc-autocomplete-panel .mat-option:first-child { margin-top: 0.5rem }

.mat-select-panel .card-footer,
.mat-mdc-autocomplete-panel .card-footer,
.card-footer.fadeout { box-shadow:0px -10px 10px -10px rgba(255, 255, 255, 1); border-top:1px solid white; background-color: $troly-grey-0; font-size:0.7rem; position:relative; }

.mat-select-panel .card-footer:last-of-type,
.mat-mdc-autocomplete-panel .card-footerlast-of-type {  border-bottom-right-radius: 8px;     border-bottom-left-radius: 8px; }

.mat-select-panel .mat-mdc-option:last-child:not(.card-footer),
.mat-mdc-autocomplete-panel .mat-mdc-option:last-child:not(.card-footer) { margin-bottom: 0.5rem; }

.mat-placeholder-required.mat-form-field-required-marker {
	color: $troly-danger; /* default * placedholder for required fields */
 }