//
//  Sweetalert2
//

.swal2-container {
    &.swal2-top .swal2-popup{
        margin-top: 7rem;
    }
	.swal2-popup {
		padding: 0; /* remove all padding so we can apply a footer color to match bootstrap card footer*/
		&.swal2-icon-warning {
			box-shadow: 0px 0px 70px rgba(var(--bs-danger-rgb), 40%);
		}
		&.swal2-icon-error {
			box-shadow: 0px 0px 70px rgba(var(--bs-yellow-rgb), 40%);
		}
		&.swal2-icon-question {
			box-shadow: 0px 0px 70px rgba(var(--bs-primary-rgb), 40%);
		}
		&.swal2-icon-info {
			box-shadow: 0px 0px 70px rgba(var(--bs-light-rgb), 40%);
		}
	}
}

.swal2-actions .btn { /* ensure buttons have a standard spacing around them */
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
}
.modal-actions {
    margin-top:0.5rem !important;
    text-align: center;
    .btn { /* ensure buttons have a standard spacing around them */
        margin-left: 0.5rem !important;
    }
}

.swal2-container {

    
    .swal2-title {
        font-size: 1.1rem;
        padding-top: 1.5rem;
		  padding-bottom: .5rem;
        color: $troly-dark;
        font-weight: 600;
    }

    .swal2-actions {
        margin: 1.5em 1.5em 2.25em 1.5em;
    }

    .swal2-html-container {
        font-size: 0.85rem;
        line-height: 1.3rem;
    }
    .swal2-footer {
        font-size:0.8em;
        margin-top: 0.2em;
        padding:1rem;

		  border-bottom-right-radius: 5px;
    		border-bottom-left-radius: 5px;
        
        /** match bootstrap card/modal footer */
        background-color: $card-cap-bg;

        a { cursor: pointer; }
    }

    .swal2-icon {

        width: 4em;
        height: 4em;
        border-width: 2px;
		margin-top: 2.75rem;
    
        .swal2-icon-content { font-size: 2.8em !important; }
    
        &.swal2-info {
            border-color: $troly-grey-4;
            color: $troly-grey-4;
            background-color: rgba($troly-grey-4, 0.25);
        }
        &.swal2-question {
            border-color: $troly-primary;
            color: $troly-primary;
            background-color: rgba($troly-primary, 0.25);
        }
        &.swal2-error {
            border-color: $troly-warning;
            color: $troly-warning;
				background-color: rgba($troly-warning, 0.25);
				
				& [class^=swal2-x-mark-line] { 
					background-color: $troly-warning; /* for some reason the error icon is painted with lines instead of a character like others!? */
					&[class$=right] { right: 0.5em; top: 1.85em; transform: rotate(0); }
					&[class$=left] { display:none; }
				}
        }
        &.swal2-warning {
            border-color: $danger;
            color: $danger;
            background-color: rgba($troly-danger, 0.25);
        }
        &.swal2-success {
            border-color: $success;
            color: $success;
            background-color: rgba($troly-success, 0.25);
        }
    }
}

.swal2-styled {
    &:focus {
        box-shadow: none;
    }
}

.swal2-progress-steps {
    .swal2-progress-step {
        &.swal2-active-progress-step {
            background: $primary;
            & ~ .swal2-progress-step,
            & ~ .swal2-progress-step-line {
                background: rgba($troly-primary, 0.3);
            }
        }
    }
}


body[data-sidebar='dark'] {
	.swal2-popup { 
		background-color: $troly-black; 
		border: 1px solid rgba(255,255,255,0.5);
		.swal2-title { color: $troly-grey-2; }
		.swal2-html-container { color: $troly-grey-3; }
		.swal2-footer { background-color: $troly-grey-9; border-top:none; }
	}
}


.alert code { color: $troly-black; }
.alert blockquote { /* blockquotes are used to display important order notes in processing popups */
	margin-top: 0.6rem;
	margin-bottom: 0.5rem;
	border-left: 3px solid var(--bs-alert-link-color);
	background-color: var(--bs-alert-border-color);
	padding: 2px 2px 2px 0.65rem;
	margin-left: 0.1rem; 
}
.alert.smaller {
	--#{$prefix}alert-padding-x: calc(#{$alert-padding-x} * 0.85);
	--#{$prefix}alert-padding-y: calc(#{$alert-padding-y} * 0.85);
	
	& > h6, p { font-size: 85%; }
	&.alert-dismissible {
		padding-right: calc(#{$alert-dismissible-padding-r} * 0.7);
	}
}