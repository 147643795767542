

div.introjs-tooltipReferenceLayer { 
    /**
     * introjs is not an angular module, instead, we `import *` in introJs.Service. 
     * as a result, the module css is included after the application defined css (like this one)
     * and adding the div element (above) adds just enough weight to make the selector more powerful.
     * no need to use `!important` -- c'mon people!!
     */
     
    * { font-family: 'Montserrat'; } /* use the brand font */


    .introjs-tooltip-header { display: none; } /* remove the header -- titles are included in the body as h3*/
    
    .introjs-tooltip { 
        min-width: 450px;max-width: 500px; /** adjust the popup width */
        
        h3 { padding-bottom:0.75rem; font-weight: 700; }
        p { 
            font-size:0.9rem; 
            strong { font-weight:700; }
        }

    }
    
    .introjs-button { background-color: $primary; border:none; text-shadow: none;  color: white; text-transform: uppercase; }
    
    .introjs-bullets ul li a:focus, 
    .introjs-bullets ul li a:hover, 
    .introjs-bullets ul li a.active { background-color: $primary; } /* set the progress indicator to be on brand */
    
    /** adjusting the steps layout padding with the body */
    .introjs-tooltiptext p:last-child { margin-bottom: 0; }
    .introjs-bullets { padding-top: 0; }
    
    .introjs-tooltipbuttons { border: none; padding: 4px 20px 20px 20px; }
}

div.introjs-helperLayer {
    box-shadow: rgb(255 255 255 / 80%) 0px 0px 1px 2px, rgb(33 33 33 / 65%) 0px 0px 0px 5000px !important;
}

.introjs-tooltip.pellet-tooltip a { color: $warning; }


body[data-sidebar='dark'] {

	div.introjs-helperLayer {
		box-shadow: rgb(0 0 0 / 95%) 0px 0px 1px 2px, rgb(0 0 0 / 80%) 0px 0px 0px 5000px !important;
  }
  
    .introjs-tooltip { background:$troly-black; h3, p { color: $troly-grey-1; } 
        .introjs-arrow {
            &.top { border-bottom-color:$troly-black; }
            &.bottom { border-top-color:$troly-black; }
            &.left { border-right-color:$troly-black; }
            &.right { border-left-color:$troly-black; }
        }
    }
}