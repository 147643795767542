.badge.with-link {
	cursor: pointer;
	& > a { color: $white; }
	&:hover {
   	box-shadow: 0 0.125rem 0.25rem $troly-grey-2;
	}
}

.badge { margin-right:2px;text-transform:uppercase; vertical-align:middle; }
h6 .badge { vertical-align:bottom; }


body[data-sidebar='dark'] {
	.badge.badge-soft-dark, .badge.bg-dark.bg-soft {
		color: rgba($white, 0.8);
		background-color: rgba($dark, 0.8);
	}
	.badge.badge-soft-success, .badge.bg-success.bg-soft {
		color: rgba($white, 0.8);
		background-color: rgba($success, 0.6);
	}
	.badge.badge-soft-danger, .badge.bg-danger.bg-soft {
	color: rgba($white, 0.8);
	background-color: rgba($danger, 0.6);
	}
	.badge.badge-soft-pink, .badge.bg-pink.bg-soft {
		color: rgba($white, 0.7);
		background-color: rgba($pink, 0.3);
	}
	.badge.badge-soft-primary, .badge.bg-primary.bg-soft {
		color: rgba($white, 0.8);
		background-color: rgba($primary, 0.7);
	}
}


.mat-mdc-option span.badge {
	vertical-align: top;
}

.badge.bg-troly { 
	background-image: linear-gradient(165deg, var(--bs-primary), var(--bs-green)); color:var(--bs-white);
	&.bg-soft {
		background-image: linear-gradient(165deg, rgba(var(--bs-primary-rgb),0.7), rgba(var(--bs-green-rgb),0.7)); 
	}
}