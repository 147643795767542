/*
Template Name: Skote - Admin & Dashboard Template
Author: Themesbrand
Version: 3.0.0
Website: https://themesbrand.com/
Contact: themesbrand@gmail.com
File: Main Css File
*/

@import "bootstrap";

//Fonts
@import "custom/fonts/fonts";

@import "../scss-troly/theme";

// Structure
@import "custom/structure/general";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
//@import "custom/structure/right-sidebar";       -- included in the original Skote v3, however not used in Troly v3
@import "custom/structure/vertical";
@import "custom/structure/horizontal-nav";
@import "custom/structure/layouts";

// Components
@import "custom/components/waves";
@import "custom/components/avatar";
@import "custom/components/accordion";
@import "custom/components/helper";
@import "custom/components/preloader";
@import "custom/components/forms";
@import "custom/components/pagination";            //-- somewhow missing from Skote v3, yet the file was included
@import "custom/components/widgets";
// @import "custom/components/demos";              -- included in the original Skote v3, however not used in Troly v3
@import "custom/components/print";

// Plugins
@import "custom/plugins/custom-scrollbar";
// @import "custom/plugins/calendar";              -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.

//@import "custom/plugins/dragula";
//@import "custom/plugins/range-slider";
@import "custom/plugins/sweatalert2";
@import "custom/plugins/rating";
@import "custom/plugins/toastr";
//@import "custom/plugins/parsley";
@import "custom/plugins/select2";

// @import "custom/plugins/switch";                -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.
@import "custom/plugins/colorpicker";
@import "custom/plugins/datepicker";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/form-editors";
// @import "custom/plugins/form-upload";           -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.
// @import "custom/plugins/form-wizard";           -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.
@import "custom/plugins/datatable";
@import "custom/plugins/responsive-table";
@import "custom/plugins/apexcharts";
// @import "custom/plugins/chartist";              -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.

@import "custom/plugins/echarts";
@import "custom/plugins/google-map";
@import "custom/plugins/leaflet-maps";
// @import "custom/plugins/owl-carousel";       -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.
// @import "custom/plugins/lightbox";           -- included in the original Skote v3, however not used in Troly v3 and the required npm packages are not installed either.

// Pages
// @import "custom/pages/authentication";       -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/ecommerce";            -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/email";                -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/file-manager";         -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/chat";                 -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/projects";             -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/contacts";             -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/crypto";               -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/coming-soon";          -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/timeline";             -- included in the original Skote v3, however not used in Troly v3
// @import "custom/pages/extras-pages";         -- included in the original Skote v3, however not used in Troly v3

@import "./custom";

// rtl
// @import "custom/rtl/general-rtl";
// @import "custom/rtl/bootstrap-rtl";
// @import "custom/rtl/spacing-rtl";
// @import "custom/rtl/float-rtl";
// @import "custom/rtl/text-rtl";
// @import "custom/rtl/structure-rtl";
// @import "custom/rtl/plugins-rtl";
// @import "custom/rtl/components-rtl";
// @import "custom/rtl/pages-rtl";