.modal-header { 
   border-bottom:none; 
	display:block; /* default is flex, but we need the same behaviour as a normal card. */
}

.modal-footer { 
   display: block;
   --bs-modal-footer-bg: var(--bs-gray-100);
}

.modal-footer > .row {
	/* adjust the modal footer to match the card footer */
	margin-top: calc(-1 * var(--bs-gutter-y));
	margin-right: calc(-0.5 * var(--bs-gutter-x));
	margin-left: calc(-0.5 * var(--bs-gutter-x));
}

body[data-sidebar='dark'] {
   .modal-header .btn-close { 
      background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFFFFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat
    }
   .modal-content { border-color: black;}

   .modal-footer { --bs-modal-footer-bg: var(--bs-gray-700);border-top:none;}
}