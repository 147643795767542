

.roll-up-down { max-height:75px; transition: max-height 0.2s ease-in-out; overflow:hidden; }
.roll-up-down.two-lines { max-height:150px; }
.roll-up-down.four-lines { max-height:350px; }
.roll-up-down.collapsed { max-height:0 !important;margin-bottom:0; }

@keyframes pulsate {
    from { -webkit-transform: scale(1.0); }
    to { -webkit-transform: scale(1.1); }
}
.bx-highlighting { /* just to use the same boxicons standards of "bx-flashing" etc */
    animation: highlighter 20s infinite;
}
@keyframes highlighter {
    0%   { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    5%  { box-shadow: 0 0 25px $warning; }
    10% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    15%  { box-shadow: 0 0 25px $warning; }
    20% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    25%  { box-shadow: 0 0 25px $warning; }
    30% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    35%  { box-shadow: 0 0 25px $warning; }
    40% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    45%  { box-shadow: 0 0 25px $warning; }
    50% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    55%  { box-shadow: 0 0 25px $warning; }
    60% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    65%  { box-shadow: 0 0 25px $warning; }
    70% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    75%  { box-shadow: 0 0 25px $warning; }
    80% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    85%  { box-shadow: 0 0 25px $warning; }
    90% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
    95%  { box-shadow: 0 0 25px $warning; }
    100% { box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 25%); }
}



.bx-flash, .bx-flash td { animation: 6s blueHighlight forwards; }
.bx-flash-fast, .bx-flash-fast td {  animation: 1.2s yellowHighlight forwards; }
@keyframes yellowHighlight { from { background-color: rgba(255,196, 0, 0.6); } to { background-color: var(--bs-card-bg); } }
@keyframes blueHighlight { from { background-color: rgba(2, 136, 209, 0.6); } to { background-color: var(--bs-card-bg); } }

:host-context(body[data-sidebar='dark']) {
	/*@keyframes yellowHighlight { from { background-color: rgba(2,136, 209, 0.6); } to { background-color: inherit; } }*/
	.bx-flash-fast, .bx-flash-fast td {  animation: 1.2s yellowHighlight forwards; }
}


.bx-burst-once { -webkit-animation: burst-big 1.25s linear; animation: burst-big 1.25s linear; }

.cursor-pointer { cursor:pointer; }
.cursor-default { cursor:default; }
.cursor-not-allowed { cursor:not-allowed; }

/** show a nice hover effect on help button **/
.bx.bx-help-circle.cursor-pointer:hover {
	&:before { content:"\ede5"; }
}

@keyframes burst-big {
	0% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
    60% { -webkit-transform: scale(2.5); transform: scale(2.5); opacity: 0; }
	 61% { -webkit-transform: scale(1); transform: scale(1); }
	 90% { opacity: 0; }
	 100% { opacity: 1; }
}
@-webkit-keyframes burst-big {
    0% { -webkit-transform: scale(1); transform: scale(1); opacity: 1; }
    60% { -webkit-transform: scale(2.5); transform: scale(2.5); opacity: 0; }
	 61% { -webkit-transform: scale(1); transform: scale(1); }
	 90% { opacity: 0; }
	 100% { opacity: 1; }
}