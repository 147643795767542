@import "../../colours";

.table {

	margin-bottom: 0; 
	h3,h4,h6,p { padding:0; }

	.row { --bs-gutter-x:0;--bs-gutter-y:0; }

	td, th, div[class^='col-']  { /** default cell padding */
		padding: 0.75rem 0.5rem;
	}
	td, th { 
		&:first-of-type { width: 50px; text-align:center; } /* most of the time this is the "more options" button */ 
		&:last-of-type { width: 20px; text-align:right; } /* most of the time this is the "more options" button */ 
	}
	td:last-of-type button.dropdown-toggle { padding:0; /**/  }
	td { padding:0.75rem 0.5rem 0.5rem 0.5rem; }

	td, div[class^='col-'] {
		/* general styling of table cells content */
		vertical-align: top;
		h6 { white-space: nowrap; font-weight: 600; margin-bottom:0rem; }
		h6 + p { margin-top:0.4rem; }
		& > p, & > ul, & > ol { margin-bottom:0; font-size: smaller; 
			li { margin-bottom:0.25rem; }
		}
		p > .badge { font-size:x-small; }
		p + p { margin-top:2px; /* two pars next two each other need a tiny bit of spacing*/ }
	}
	& > div.row > div[class^='col-'] > * {
		padding: unset; /* overrides the default .table forcing padding on cells */
		background: unset; /* overrides the default .table forcing background on cells */
	} 

	/** colouring, hovers and and borders **/
	thead th, .row.thead {
		border-bottom:2px solid rgba($troly-grey-0, 0.75);
		vertical-align: middle;font-weight: 600;font-size: 11px; 
	}
	
	&.table-striped {
		& > .row:not(.thead):hover > div { /*mimick the bootstrap row HOVER */ background-color: var(--bs-table-hover-bg) !important; }

		& > .row {
			background-color: var(--bs-white);
		}
		& > :nth-child(even) {
			background-color: var(--bs-table-striped-bg);
		}
	}
	

	tbody > tr:not(.table-active) { --bs-table-bg: var(--bs-white); }
	tr.table-active td {
		--bs-table-color-state: var(--bs-table-active-color);
		--bs-table-bg-state: var(--bs-table-active-bg); /** overriding the hover colour */
	}

	tbody > tr:hover, > .row:not(.thead):hover {
		button:not(.btn).dropdown-toggle { opacity:1; }
	}
	tr td, > .row { border-bottom:1px solid $troly-white; } /* all rows have a 1px separaor */
	tbody > tr:last-of-type, > .row:last-of-type {
		 border-bottom:2px solid rgba($troly-grey-0, 0.75);
	}
	caption {
		background-color: var(--bs-white) !important;
		p { text-align: center; }
	}

	tr.subtotal td { background-color:$troly-grey-1;border-top:1px solid $troly-grey-8; border-bottom:2px solid $troly-primary !important;}
	tr.grandtotal td { border-top:2px solid $troly-primary; }
}

body[data-sidebar='dark'] {
	.table { 
		--bs-table-bg: rgba($troly-grey-7,0.3);
		tbody > tr:not(.table-active) { --bs-table-bg: var(--bs-table-bg); }
		.row.thead .th, thead th { color:$troly-grey-1; }
		h6 { color:$troly-grey-1; }
		
		&.table-striped {
			& > tbody > tr, & > .row > div[class^="col-"]  { background-color: rgba($troly-grey-7,0.3); }
			& > .row { background-color: var(--bs-gray-600); }
			& > :nth-child(even), tr > :nth-child(even) {
				background-color: var(--bs-table-striped-bg);
				}
			}

		tbody tr:hover, .row:not(.thead):hover { --bs-table-bg: rgba($troly-grey-0, 0.08); }
		tr.subtotal td { background-color:$troly-grey-6; }
		tr td, tr th, .row { border-bottom-color:$troly-grey-9; }
		
		tr td i { color:$troly-grey-0; }

		caption {
			background-color: transparent !important;
		}
	}
}

.selectable {
	tbody > tr > td:first-of-type {     width: 48px; min-height: 60px; padding-top: 8px; padding-right: 0; }
	&.selecting {
		/** disable text selection on bulk editing - to elegantly support shift-click to select */
		user-select: none; // chrome and Opera
		-moz-user-select: none; // Firefox
		-webkit-text-select: none; // IOS Safari
		-webkit-user-select: none; // Safari
	}
}

.sortable {
	th[sortable] {
		 cursor: pointer;
		 user-select: none;
		 -webkit-user-select: none;
		 &:after { margin-right:2px; float:left; opacity:0; transition:0.25s ease-in-out; transform:translateY(25%) }
		 &:hover {
			  &:after { opacity:0.8; transform: translateY(0%); }
		 } 
	}

	th[sortable]:after,
	th[sortable].desc:after,
	th[sortable].asc:after {
		 content: "\eab7";
		 font-family: 'boxicons';
		 color:$dark;
		 line-height: 16px;
		 font-size: 16px;
	}

	th[sortable].asc:after { transform:translateY(0%); opacity:1; color:$danger; content: "\eab7"; }
	th[sortable].desc:after { transform:translateY(0%); opacity:1; color:$danger; content: "\ec5d"; }

}
body[data-sidebar='dark'] {
	.sortable th[sortable]:after {
		color:$troly-grey-2;
	}
}