
.mat-mdc-form-field, .mat-mdc-select, .mdc-label, .mat-slide-toggle-content {
   width:100%; /* we use bootstrap columns to create forms, so the field should always take as much width as possible */
   font-size: 13px;
	
	
	.mat-mdc-floating-label { 
		margin-top:-2px;
		font-size: 12px;
		&.mdc-floating-label--float-above { font-size: 12px; }
		.mat-mdc-form-field-required-marker { color: $troly-danger;font-size: 12px; } 
	}

	.mat-mdc-text-field-wrapper:not(.mdc-text-field--disabled) { color: rgba(0, 0, 0, 0.9) !important; }

	.mat-mdc-form-field-subscript-wrapper { font-size:9px; }
	&.mat-form-field-disabled .mat-mdc-form-field-hint { display:none; }

	.mat-mdc-form-field-infix { min-height:50px; }
	.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) {
		margin-top:0em; padding-left:10px !important;
		.mat-mdc-form-field-infix { padding-bottom: 1px; }
	}

}

/** indent a 'descriptor' if present after a checkbox/radio */
mat-checkbox + p.font-size-11 { margin-left: 2.5rem; }

.mat-mdc-form-field-icon-suffix .mat-mdc-select { margin-right:5px; }
mat-option.mat-mdc-option { --mat-option-label-text-size: 12px; min-height:35px; line-height: 13px; gap:4px; }
mat-option.two-lines-option.mat-mdc-option { min-height:55px; }


.cdk-overlay-container { z-index:1200; }

.mat-mdc-autocomplete-panel.search {

	max-height:unset; 
	padding-bottom:0;
	overflow:unset; /* removes the default 'auto' which prevents ngbdropdown create menu from showing in search-dropdown*/
	mat-option:last-of-type { margin-bottom:8px; }
	
	&:before {
		/** inserting a loader icon (HIDDEN) */
		content: " ";
		width: 35px;
		height: 35px;
		animation: spinner-border 0.8s linear infinite;
		border: 3px solid $troly-primary;
		border-radius: 50%;
		border-right-color: transparent !important;
		left: calc(50% - 17px);
		top: 21%;
		position: absolute;
		opacity:0;
	}

	&.loading::before { /** showing the loader icon */ opacity:0.8; }

	h6 { /* in the results and intro */
		margin-top:0.5rem;
		margin-bottom:0rem;
		color: var(--bs-dark);
		font-weight: 400;
	}

	p { font-size: 12px; } /** in the footer and results */

	.search-intro {
		
		padding:0.75rem !important; 
		opacity:0.7;

		.avatar-title {
			height:50px; width:50px; margin:3px; font-size: 18px;
			i { text-align: center; color: var(--bs-secondary); }
		}
		h6 { text-align: center; color: var(--bs-secondary); }

		&.text-danger {
			h6, i { color: $troly-danger; } /* no match error message */
		}
	}

	.search-result {
		padding:0.25rem; 
		.mdc-list-item__primary-text {  width:100%; display:flex; /* auto-organise contained the elements */}
		h6 { font-weight: 600;margin-top:0.25rem; }
		.avatar-xs { margin-right:0.75rem;margin-left:0.5rem; }
		p { margin-bottom: 0;}

		&.icon-vertical-center { .avatar-xs { padding-top:0.65rem; } }
	}

	.card-footer { /** instructions, status and options*/
		justify-content: space-between;
		display: flex;
		padding:0.4rem;
		margin-top:6px;
		p { margin-top:0.5rem;margin-bottom:0.3rem;opacity:0.6; }
	}
}

/**
 * Icons Section // prefix+suffix used in text boxes and date pickers
 */

.mat-mdc-form-field-icon-prefix > i, .mat-mdc-form-field-icon-suffix > i { font-size:19px; color:var(--bs-secondary); }			/* simple aesthetic for icons in as prefix suffix in forms */
.mat-mdc-form-field-icon-prefix > span, .mat-mdc-form-field-icon-suffix > span { position:relative; margin-right: 0.2rem; }								/* simple aesthetic for icons in as prefix suffix in forms */
div.mat-mdc-form-field-icon-prefix > span { top:0.5rem; }
div.mat-mdc-form-field-icon-suffix > span { top:0.6rem; margin-right:0.5rem; font-size: var(--mat-select-trigger-text-size); }																								/* simple aesthetic for icons in as prefix suffix in forms */
div.mat-mdc-form-field-icon-prefix > i { padding-right:6px; }
div.mat-mdc-form-field-icon-suffix > i { padding-right:13px; }
div.mat-mdc-form-field-icon-suffix { --mat-select-trigger-text-size:11px; }
mat-select-trigger { display: block; height: 28px; padding-top: 18px; color:$troly-dark; }


/**
 * Radio buttons ahd checkboxes
 */
.mat-mdc-checkbox:not(.mat-primary) { .mdc-label { margin-bottom:0; }}
.mat-mdc-radio-button.mat-primary,
.mat-mdc-checkbox.mat-primary { .mdc-label {
	margin-top:0.6rem; cursor: pointer; text-transform: capitalize;
}}
.mat-mdc-checkbox[class*='text-inherit'] { .mdc-label { text-transform:inherit; }}


/** selected is bold */
.mat-mdc-radio-checked.mat-primary, .mat-mdc-checkbox-checked.mat-primary { .mdc-label { font-weight: 600; color: var(--bs-primary) !important; } }
.mat-mdc-radio-checked.mdc-checkbox--disabled, .mat-mdc-checkbox-checked.mdc-checkbox--disabled { .mdc-label { color: var(--bs-secondary) !important; } }
/*match color in alert*/ ngb-alert[type].alert .mat-mdc-checkbox .mdc-label { color: var(--bs-alert-color); font-size:12px; margin-top:0.2rem;margin-bottom:0.1rem; }

/* disabled is grey */
.mat-mdc-radio--disabled,
.mat-mdc-checkbox--disabled { .mdc-label {
	color: var(--bs-light) !important
}}

/**
 * Text boxes fill colours for standard, error and hover
 */
/** MATERIAL APPEARANCE == FILL: BACKGROUND COLOURS **/
.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper {
   /* overrides the default material fill color */
   --mdc-filled-text-field-container-color: var(--bs-light-bg-subtle);
   border-radius: 0.4em 0.4em 0 0;
	transition: background-color 0.1s ease-in-out;
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.mat-form-field-appearance-fill.mat-form-field-invalid .mat-mdc-text-field-wrapper {
   background-color:rgba($troly-danger,0.1) !important; /* red fill background on error */
}

.mat-mdc-standard-chip.mst-mdc-chip-selected { opacity:0.9; }

.card:not(.no-hover):hover {
   /* when in a card (always is?!) on hover, use apply a troly-blue brand hint */
   .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper { --mdc-filled-text-field-container-color: rgba(var(--bs-body-bg-rgb), 0.7); }
	.mat-mdc-chip-listbox .mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-selected) { background-color: rgba($troly-primary, 0.03); }
	.mat-mdc-standard-chip.mst-mdc-chip-selected { opacity:1; }
   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
   .mat-mdc-form-field:not(.mat-form-field-disabled) label.mdc-label { color:$dark; }
}

/***
 * Dark Tweaks 
 */
body[data-sidebar='dark'] {

	.mat-mdc-form-field-icon-prefix > i, .mat-mdc-form-field-icon-suffix > i, .mat-mdc-form-field-icon-prefix > span, .mat-mdc-form-field-icon-suffix > span, 
	mat-label, .mat-mdc-checkbox:not(.mat-mdc-checkbox-checked) .mdc-label, .mat-mdc-radio-button:not(.mat-mdc-radio-checked) .mdc-label { color:var(--bs-light) !important; }			/* simple aesthetic for icons in as prefix suffix in forms */


	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-disabled .mat-checkbox-label {
		color: var(--bs-gray-600) !important
	}

	.mat-mdc-chip-listbox .mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-selected) { border-color:transparent; }
   
   ::placeholder {
      color:$troly-grey-1;
   }
   
   .mat-mdc-form-field, .mat-form-field-label { color: var(--bs-light) !important; }
	
   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
   .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper, .mat-slide-toggle-bar,
	.mat-mdc-chip-listbox .mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-selected) { 
		background-color: rgba($troly-grey-0,0.25); 
		&.mdc-text-field--disabled { .mdc-text-field__input, .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix { color: $troly-grey-3 !important; } }
	}
   .card:hover {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-appearance-fill .mat-mdc-text-field-wrapper, .mat-slide-toggle-bar,
	   .mat-mdc-chip-listbox .mat-mdc-chip.mat-mdc-standard-chip:not(.mat-mdc-chip-selected) {
         background-color: rgba($troly-grey-0,0.3);
      }
      .mat-mdc-form-field, .mat-mdc-form-field-icon-prefix, .mat-mdc-form-field-icon-suffix { color: $troly-white; }
      
      
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-mdc-form-field:not(.mat-form-field-disabled) label.mat-form-field-label { color:$troly-grey-1; }
   }
	.mat-mdc-select-value-text { color: $troly-grey-0; }
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
	.mat-form-field-prefix button, .mat-form-field-suffix button { color:$troly-grey-3; } /* simple aesthetic */
   /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
   .mat-hint, .mat-tab-label, .mat-tab-link, .mat-checkbox-label, .mat-slide-toggle-content, .mat-radio-label-content { color:$troly-grey-3; text-shadow:none; }
   /* text colour of the form field label when hovered */
   .mat-mdc-form-field:hover:not(.mat-focused):not(.mat-form-field-disabled) {
      /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      .mat-form-field-label { color: $troly-grey-0; }
   }
   /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
   .mat-mdc-form-field .mat-form-field-ripple { background-color:$troly-primary; }
   /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
   .mat-select-value, .mat-select-arrow { color:$troly-grey-1; }

	/* inverting material checkboxes */
	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-frame, .mat-radio-outer-circle { border-color: $troly-grey-5; }
	/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
	.mat-checkbox-disabled .mat-checkbox-frame { border-color: $troly-grey-7; }
	
	.mat-form-field-disabled .mat-mdc-form-field-icon-suffix {
		opacity:0.6;
	}

	.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input { color:$troly-grey-1; }
	
}






.mat-mdc-radio-group {
	h6 { small.text-danger { font-size:90%;margin-left: 0.25rem; } } /* ensure enough space for the "required" asterisk */
	/* ensure there's some spacing between radio buttons */
	.mat-mdc-radio-button { 
		margin-right: 1.8rem; 
		.mdc-radio {
			--mdc-radio-state-layer-size: 36px;
	  	} 
	}
}

/**** Form Fields Status Indicator **/
.mat-mdc-text-field-wrapper:before {
   font-family: 'boxicons';position: absolute;right: 2px;
    top: 3px;
    height: 15px !important;
    width: 15px !important;
    line-height: 15px !important;
    font-size: 15px;
}
/* Material Error Field -- show an error icon within the field */
mat-form-field.ng-invalid.ng-touched .mat-mdc-text-field-wrapper:before {
   content: "\eda4";
   color:$danger;
}

/** loading indicator on forms fields -- disabled using .no-live-loading on the form or field */
form.ng-dirty:not(.no-live-loading) { /* only on forms which do not have a no-live-loading class */

	mat-form-field.ng-dirty:not(.no-live-loading):not(.ng-invalid) {
	/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
		.mat-mdc-text-field-wrapper:before { /* setting up on all fields which do not have an explicit 'no-live-loading' class */
			content: "\eb46";
			display:block;
			animation: spin 0.8s linear infinite;
			opacity: 0;
			transition: opacity 0.6s ease-out;
			color: var(--bs-primary); 
		}
		/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
      &.ng-touched  .mat-mdc-text-field-wrapper:before {
			opacity: 0.9;
		}
	}
}

.ngx-mat-color-palette form mat-form-field .mat-mdc-text-field-wrapper:before { display:none; }

form:not(.no-live-loading) mat-form-field:not(.no-live-loading).ng-touched.ng-pristine.ng-valid {
	.mat-mdc-text-field-wrapper:before {
		content: "\ed31";
		display:block;
		color: var(--bs-success); 
		opacity: 1;
		animation: fadeout 2s ease-in;
		animation-fill-mode: forwards; /* This declaration stops the annimation from looping back to initial state */
	}
}
@keyframes fadeout {
	from { opacity: 1; }
	to { opacity: 0; }
}

.bx.bxs-check-circle.bx-fadeout { /* used as h6 icon to show loading and success indicators */
	opacity: 1;
	animation: fadeout 2s ease-in;
	animation-fill-mode: forwards; /* This declaration stops the annimation from looping back to initial state */
}

form textarea { 
	height: 4.2rem !important;line-height: 1.4em !important; 
	resize: none !important;
}

/* default our fields to being 100% of the available width */
.mat-mdc-form-field {
   .mat-mdc-form-field-hint {
      visibility: hidden;
      text-shadow: 0 0 15px yellow; margin-top: -3px;
   }
	.mat-mdc-form-field-error { margin-top: -3px; }

   &:hover, &.mat-focused {
   .mat-mdc-form-field-hint { /* only display the mat-hints when focused */
           visibility: visible;
       }
   }
   &.mat-focused {
       .mat-mdc-form-field-hint { /* darken when the field is currently used */
           color: $dark;
       }
   }
   /* text colour of the form field label when hovered */
   &:hover:not(.mat-focused):not(.mat-form-field-disabled) {
       /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
       .mat-form-field-label {
           color: $secondary;
       }
   }
}

.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper, div.mat-mdc-form-field-error-wrapper { padding-left:9px;padding-right:0; /* remove of some the spacing before/after the hin+error to better align with inputbox content */}


.alert { /* applying standard styling to alert heading */
   h6 { padding-top:0 !important;margin-top: calc($headings-margin-bottom/2);font-weight:600; text-transform:capitalize; }
   p, h6, ul, li, blockquote { font-size: 12px;color:var(--bs-alert-color) !important; /*overrides rebood and card:hover*/  }
	p {
		&:last-of-type { margin-bottom:0; }
	}
	&.alert-dismissible button.btn-close { font-size:8px; }
	em { font-size:11px;font-style: normal;padding: 0.1em 0.25em; background-color: var(--bs-alert-border-color); }
}

/* allow removing the mat-select trigger chevron and replace with custom -- see gender and salutation */
.mat-mdc-select.remove-select-trigger .mat-mdc-select-arrow-wrapper {
	display:none;
}

/** making the focussed fields not so dark */
.mat-mdc-form-field-focus-overlay { background-color: rgba(0, 0, 0, 0.1); }


/** hide arrows from number input fields see https://www.w3schools.com/howto/howto_css_hide_arrow_number.asp*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }

form {
	--mdc-checkbox-state-layer-size: 35px; /*making the checkbox ripple smaller */
}


mat-tab-group {
	--mat-tab-header-label-text-font: 'Montserrat', sans-serif;
	.mat-mdc-tab-labels { border-bottom:1px solid var(--bs-primary); font-weight: 600; }
	.mdc-tab--active { font-weight:700; }
	.mat-mdc-tab-body-content p { font-size:12px;line-height:17px; &.smaller { line-height:14px; } }
	.mat-mdc-tab-body-content div.col-4 img.qr_code { width:7.5vw; max-width:150px; }
}

body[data-sidebar='dark'] {

	.mat-mdc-form-field.mat-focused {
		.mat-mdc-form-field-hint { /* darken when the field is currently used */
			 color: var(--bs-gray-200);
		}
  	}

	.mat-mdc-tab .mdc-tab__text-label {
		--mat-tab-header-inactive-label-text-color: var(--bs-light);
	}

	/* properly colourise the checkboxes and radiobuttons in dark */
	.mat-mdc-radio-button .mdc-form-field {
		--mdc-radio-unselected-icon-color: var(--bs-light);
		--mdc-radio-unselected-focus-icon-color: var(--bs-gray-100);
		--mdc-radio-unselected-hover-icon-color: var(--bs-gray-100);
		--mdc-theme-text-primary-on-background: var(--bs-gray-100);
	}
	.mat-mdc-checkbox .mdc-form-field {
		--mdc-checkbox-unselected-icon-color: var(--bs-light);
		--mdc-checkbox-unselected-focus-icon-color: var(--bs-gray-100);
		--mdc-checkbox-unselected-hover-icon-color: var(--bs-gray-100);
		--mdc-checkbox-disabled-selected-icon-color: var(--bs-gray-600);
		--mdc-checkbox-disabled-unselected-icon-color: var(--bs-gray-600);
		--mdc-theme-text-primary-on-background: var(--bs-gray-100);
	}
}



.height-none { overflow: hidden; transition: .4s ease-in-out; max-height:0; }
.height-auto { max-height:70px; } 


mat-slider.no-value-entered {

	--mdc-slider-handle-color: var(--bs-gray-200);
	--mdc-slider-active-track-color: var(--bs-gray-200);


	--mdc-slider-inactive-track-color: var(--bs-gray-200);

	--mdc-slider-handle-height: 20px;
	--mdc-slider-handle-width: 20px;

}