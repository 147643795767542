
// dropdown
.dropdown-toggle {
    &:after {
        display: none;        
    }
}


// list of troly overrides of the standard Skote scss files.
body[data-sidebar='dark'] {
    /* remove the skote-defined background on the logo */
    background-color: $troly-grey-8;
}
 
.mat-mdc-standard-chip, // default/Poppins is very narrow when uppercased
.badge, /* badges in headers and badges in paragraphs should look the same */
h1,h2,h3,h4,h5,h6, nav, th {  font-family: $headings-font-family;  }

.text-disabled { color: #a8a8a8; }

//@import "../scss-troly/theme";
@import "../scss-troly/tinymce";

@import "../scss-troly/custom/components/animations";
@import "../scss-troly/custom/components/avatar";
@import "../scss-troly/custom/components/badge";
@import "../scss-troly/custom/components/breadcrumb";
@import "../scss-troly/custom/components/buttons";
@import "../scss-troly/custom/components/card";
@import "../scss-troly/custom/components/chips";
@import "../scss-troly/custom/components/dropdown";
@import "../scss-troly/custom/components/forms";
@import "../scss-troly/custom/components/nav";
@import "../scss-troly/custom/components/pagination";
@import "../scss-troly/custom/components/snackbar";
@import "../scss-troly/custom/components/table";

@import "../scss-troly/custom/structure/modal";
@import "../scss-troly/custom/structure/vertical";

@import "../scss-troly/custom/structure/transitions";

@import "../scss-troly/custom/plugins/introjs";
@import "../scss-troly/custom/plugins/sweetalert2";