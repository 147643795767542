.mdc-evolution-chip-set {


	/**
	 * The next two lines allow removing the default, check mark and replace with custom icon, correctly marked as "prefix" or matChipAvatar
	 */
	.mdc-evolution-chip--selected .mdc-evolution-chip__icon--primary { opacity:1; --mdc-chip-with-icon-icon-color:white; }
	.mdc-evolution-chip__checkmark { display:none; }
	
	.mdc-evolution-chip--with-avatar.mdc-evolution-chip--with-primary-graphic.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__graphic { padding-right:5px; /* matches the spacing on a custom trailing icon */}



	.mat-mdc-chip .mdc-evolution-chip__text-label { font-size: 0.7rem; text-transform: uppercase; font-weight:500; }

	.mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary { padding-left:0; /* remove the checkmark spacing */ }

	// ICONS
	i { margin:0; font-size: 18px; width:18px;height:18px; float:left; }
	i[matchiptrailingicon] { opacity:0; }
	.mat-mdc-chip:hover .mat-mdc-chip-remove i[matchiptrailingicon].bxs-x-circle { color:$danger; opacity:1; }
	.mat-mdc-chip.ng-dirty i[matchiptrailingicon].bx-loader-alt { animation:spin 1s linear infinite;opacity:1; }
	.mat-mdc-chip.ng-saved { 
		i[matchiptrailingicon].bxs-check-circle { opacity:1; color:$troly-success; }
		&.mat-mdc-chip-selected i[matchiptrailingicon].bxs-check-circle { opacity:1; color:$troly-yellow; }
	}

	.mdc-evolution-chip__cell--trailing, .mat-mdc-chip-remove { cursor: pointer; opacity: 1; i { cursor: pointer; } }

	


	&.mat-mdc-chip-listbox .mat-mdc-chip.mat-mdc-standard-chip { 
		/* matching the default chip bg with the forms */ 
		background-color: rgba($troly-grey-0,0.5); 
		border: 1px solid $troly-grey-2;
		&.mat-mdc-chip-selected { border-color:transparent; background-color: $troly-primary;}
	}
	&.mat-mdc-chip-grid .mat-mdc-chip.mat-mdc-standard-chip { 
		
		/* we cannot match the form bg when the chip is within a text box (Grid) */ 
		border: 1px solid rgba($troly-grey-2,0.5);
		background-color: rgba($troly-grey-2,0.5); 
		&.mat-mdc-chip-selected { border-color:transparent; background-color: rgba($troly-dark,0.8);}
	}
}