.dropdown .dropdown-menu, .mat-mdc-select-panel {
   & > h6, & > .row > div[class*="col-"] > h6 {  /* drop down dividers */
      border-top:$dropdown-border-width solid $troly-grey-1; 
      padding-top: 0.75rem; 
      padding-left: 1rem; 
		padding-right: 1rem; 
      margin-top: 0.25rem; 
      color: $troly-grey-3 !important; 
      font-weight:600;
		display:inline-block; /* overrides the default 'block' from bootstrap -- allows to flex-flow and displying top border correctly */
		width:100%;
      &:after { content:":"} 
   }

   > :first-child { border-top: none; margin-top:0; /* if the list starts with a subheader as h6, remove the border */}
	.border-bottom { border-bottom-width:8px !important; }

	.row div[class*="col-"] h6:first-of-type {
		/* when we have multiple columns - removing the top border from the first row */
		border-top-width: 0;
		padding-top: 0.5rem;
  }
}

.mat-mdc-select-panel {
	.mat-mdc-optgroup-label { min-height:unset; }

	.mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) { padding-left: 24px; }

	.mat-mdc-optgroup-label {
		border-top:$dropdown-border-width solid $troly-grey-1; 
      padding: 0.75rem 0; 
      padding-left: 1rem; 
		padding-right: 1rem; 
      margin-top: 0.25rem; 
      color: $troly-grey-3 !important; 
      font-weight:600;
	}
	.mat-mdc-optgroup:first-child .mat-mdc-optgroup-label {
		border-top: none; margin-top:0;
	}	
}

.dropdown-toggle { cursor: pointer ;}

.dropdown .dropdown-menu {
	padding-bottom:0;

	button:last-of-type, a:last-of-type {
		margin-bottom:5px;
	}
}

.dropdown-item.selected, .mat-mdc-option.selected {
   /* in any dropdown, show an indicator of what is currently selected */
   color:$primary;
   &:not(.no-icon):after {
      content: "\ea41";
      font-family: "boxicons";
      position: absolute;
      right: 0.9rem;
   }
}

.dropdown-item {

   padding-right: calc(var(--bs-dropdown-item-padding-x) * 1.25);
	margin-bottom: 2px; /* used as a bit of spacing between disabled buttons*/
	overflow: hidden;
	text-overflow: ellipsis;
	line-height:1.35rem; /* somehow it's different in the topnav and the filter dropdowns..? */

	&.disabled:not(.selected), &:disabled:not(.selected), &.unavailable { cursor: not-allowed; opacity: 0.6; }
   > i { /* format the menu item icon */
      font-size: larger;
      vertical-align: middle;
      & + span { margin-left: calc(var(--#{$prefix}dropdown-item-padding-x) / 3); }
   }

   span + .badge { /* format badges and push right */
      text-transform: uppercase;
      float:right;
		margin-right:-1rem;
   }

}

.mat-mdc-autocomplete-panel.rounded-dropdown {
	border-radius: $dropdown-border-radius !important;
	border: $dropdown-border-width solid $dropdown-border-color !important;
}

.dropdown-divider { border-top-width: $dropdown-border-width; }

.dropdown-footer {
	background-color: $troly-grey-0; /* matching the release notes widget */
	border-bottom-left-radius: 8px;
	border-bottom-right-radius: 8px;
	min-height: 35px;

	&.border-top { border-top: $dropdown-border-width solid $dropdown-border-color !important; /*important is needed to override the bootstrap utilities/mixin */ }
}

@media (min-width: 600px) {
	.dropdown-menu-lg { width: 540px; }
	.dropdown-menu-md { width: 360px; }
	.dropdown-menu-sm { width: 240px; }
	.dropdown-menu { min-width: 200px; } /* the smallest - see company templates with floating badges -- the alternative is to reorganise the floading badge css */
 }

body[data-sidebar='dark'] {
	div {
		.mat-mdc-select-panel { background-color: $troly-grey-2; }
		.mat-mdc-select-panel h6 { color: $troly-grey-6 !important; border-top-color: $troly-grey-4; }
		.dropdown .dropdown-menu .dropdown-item {
			i { color:$troly-grey-6; }
			&.text-danger i { color:$troly-danger; }
		}
	}
}