.nav-tabs > li > a > i {
      vertical-align: text-top;
      font-size: 1.1rem;
      margin-right: 0.4em;
}


.nav-tabs.troly-material-flat { /** turns the normal 'tabs' into a material-looking flat design */
   border-bottom: 2px solid $troly-dark;
   margin-bottom:1.5rem;

   .nav-item {
       position: relative;
       color: $troly-dark;
       .nav-link {
           border: none;

           &::after {
               content: '';
               background: var(--bs-primary);
               height: 4px;
               position: absolute;
               width: 100%;
               left: 0;
               bottom: -3px;
               transition: all 250ms ease 0s;
               transform: scale(0);
           }

           &.active {
               color: var(--bs-primary);
               font-weight: 700;
               background: transparent;
               &:after {
                   transform: scale(1);
               }
           }
       }
   }
}

body[data-sidebar='dark'] {

    .nav-item .nav-link { color:$troly-grey-2; }

   .nav-tabs.troly-material-flat {
      border-bottom-color: $troly-grey-2;
      .nav-item:not(.cursor-not-allowed) { 
         .nav-link {
            color: $troly-grey-2;
            &::after { background:$troly-primary; }
            &.active { color:$troly-primary; }
            &:hover, &:focus { color:$troly-primary; }
         }
      }
      .nav-item.cursor-not-allowed .nav-link { color: $troly-grey-5; }

   }
}