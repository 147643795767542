//
// Troly customisations -- avatar.scss
//

.avatar-xs { img { max-height:2rem;max-width:2rem;}}
.avatar-sm { img { max-height:3rem;max-width:3rem;}}
.avatar-md { img { max-height:4.5rem;max-width:4.5rem;}}
.avatar-lg { img { max-height:6rem;max-width:6rem;}}
.avatar-xl { img { max-height:7.5rem;max-width:7.5rem;}}


.avatar-xs .avatar-title.rounded-circle i.bx { margin-left:1px; /* this is a litle tweak to make a 17px icon better centered in a 32px (😱) container*/ }

.avatar-xs, .avatar-sm, .avatar-md, .avatar-lg, .avatar-xl { 

	.rounded-circle img { border-radius: 50%; }

	img {
		transition: transform ease-in-out 0.1s;
		z-index: 1;

		position: relative;
		&:hover { z-index: 2; transform: scale(1.8); box-shadow: $box-shadow-md; }
	}
}

.table tr:hover {
	.avatar-title { opacity:1; }
	.avatar-xs, .avatar-sm, .avatar-md, .avatar-lg, .avatar-xl {
		img {
		  transform: scale(1.8); box-shadow: $box-shadow-md;
		}
	}
}
.avatar-title { opacity:0.8; transition: opacity 0.2s; }